module.exports = {
  siteTitle: "cities-skylines.info", // <title>
  shortSiteTitle: "blog", // <title> ending for posts and pages
  siteDescription: "cities: skylinesについていろいろと書きたい気持ちが抑えられないひとです。数年の間、中古のノートパソコンで頑張ってプレイしています。重くて話にならないので、そろそろ新しいパソコンを買っちゃおうかと考え中です。",
  siteUrl: "https://cities-skylines.info",
  enSiteUrl: "https://en.cities-skylines.info",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "ja",

  /* author */
  authorName: "fuchika",
  authorTwitterAccount: "https://twitter.com/fuchika_jp",
  authorGithub: "",
  authorStackoverflow: "",
  authorLinkedin: "",
  authorCodeforces: "",
  authorYoutube: "",

  /* header text */
  headerTitle: "cities-skylines.info",
  headerSubTitle: "blog",

  /* manifest.json */
  manifestName: "fuchika",
  manifestShortName: "blog", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "",
};
